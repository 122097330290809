import request from './config/request'

export function createIntent(payload: object | {}) {
    // { point_package_id: point_package_id, point: point }
    const url = '/charge/create_intent'
    return request<any, any>({
        url,
        method: 'POST',
        data: payload,
    })
}

export function createUser(payload: object | {}) {
    // {}
    const url = '/charge/create_user'
    return request<any, any>({
        url,
        method: 'POST',
        data: payload,
    })
}

export function addCard(payload: object | {}) {
    // { token }
    const url = '/charge/add_card'
    return request<any, any>({
        url,
        method: 'POST',
        data: payload,
    })
}

export function checkPaymentIntent(payload: object | {}) {
    const url = '/charge/check_payment_intent'
    return request<any, any>({
        url,
        method: 'POST',
        data: payload,
    })
}
